var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var LI;$CLJS.JI=function(a){return $CLJS.n($CLJS.pB($CLJS.rd,$CLJS.qd,$CLJS.cl)(a))?a:new $CLJS.P(null,1,5,$CLJS.Q,[a],null)};
$CLJS.KI=function(a,b){return $CLJS.Me($CLJS.zd,function(){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l);if(k=$CLJS.y(function(t,u,v,x){return function G(C){return new $CLJS.ie(null,function(K,S,X,T){return function(){for(var da=C;;){var oa=$CLJS.y(da);if(oa){var Ma=oa,sb=$CLJS.z(Ma);if(oa=$CLJS.y(function(Qa,Xa,Ja,Ua,Ta,mb,dc,Kd){return function Rh(gd){return new $CLJS.ie(null,function(qz,Il,Un,zr,R_,Mv){return function(){for(;;){var Nv=
$CLJS.y(gd);if(Nv){if($CLJS.vd(Nv)){var Ov=$CLJS.ic(Nv),rz=$CLJS.D(Ov),Ek=$CLJS.le(rz);a:for(var Ar=0;;)if(Ar<rz){var gt=$CLJS.hd(Ov,Ar);gt=$CLJS.E.g(Mv,$CLJS.YD)||$CLJS.E.g(Un,$CLJS.YD)||$CLJS.zE(Mv,gt)&&$CLJS.zE(Un,gt);Ek.add(gt);Ar+=1}else{Ov=!0;break a}return Ov?$CLJS.oe($CLJS.qe(Ek),Rh($CLJS.jc(Nv))):$CLJS.oe($CLJS.qe(Ek),null)}Ek=$CLJS.z(Nv);return $CLJS.ae($CLJS.E.g(Mv,$CLJS.YD)||$CLJS.E.g(Un,$CLJS.YD)||$CLJS.zE(Mv,Ek)&&$CLJS.zE(Un,Ek),Rh($CLJS.Hc(Nv)))}return null}}}(Qa,Xa,Ja,Ua,Ta,mb,dc,
Kd),null,null)}}(da,K,sb,Ma,oa,S,X,T)($CLJS.jE)))return $CLJS.bf.g(oa,G($CLJS.Hc(da)));da=$CLJS.Hc(da)}else return null}}}(t,u,v,x),null,null)}}(f,m,l,k)($CLJS.JI($CLJS.LD(b)))))return $CLJS.bf.g(k,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null}},null,null)}($CLJS.JI($CLJS.LD(a)))}())};
LI=function(a){return function(){function b(d,e){var f=null;if(1<arguments.length){f=0;for(var k=Array(arguments.length-1);f<k.length;)k[f]=arguments[f+1],++f;f=new $CLJS.w(k,0,null)}return c.call(this,d,f)}function c(d,e){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,$CLJS.Ne.j($CLJS.vE,d,e),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bi,new $CLJS.h(null,1,[$CLJS.ts,"arguments should be comparable"],null),function(f){f=$CLJS.y(f);$CLJS.z(f);f=$CLJS.B(f);$CLJS.z(f);f=$CLJS.B(f);var k=$CLJS.yf(f);f=$CLJS.ND;
return $CLJS.n(f)?f:$CLJS.Le($CLJS.nl,$CLJS.cf.g(function(l){var m=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.KI($CLJS.J.g(k,m),$CLJS.J.g(k,l))},a))}],null)],null)}b.A=1;b.B=function(d){var e=$CLJS.z(d);d=$CLJS.Hc(d);return c(e,d)};b.l=c;return b}()};$CLJS.MI=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.NI=new $CLJS.M("operator","filter","operator/filter",-1518842858);
$CLJS.OI=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var PI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nr,$CLJS.hr],null)),QI=null,RI=0,SI=0;;)if(SI<RI){var Kia=QI.X(null,SI);$CLJS.yE(Kia,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ti,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)],null)],null)],null)]));SI+=1}else{var TI=$CLJS.y(PI);if(TI){var UI=TI;if($CLJS.vd(UI)){var VI=$CLJS.ic(UI),Lia=$CLJS.jc(UI),
Mia=VI,Nia=$CLJS.D(VI);PI=Lia;QI=Mia;RI=Nia}else{var Oia=$CLJS.z(UI);$CLJS.yE(Oia,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ti,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)],null)],null)],null)]));PI=$CLJS.B(UI);QI=null;RI=0}SI=0}else break}$CLJS.wE($CLJS.jr,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WD],null)]));
for(var WI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,$CLJS.zF],null)),XI=null,YI=0,ZI=0;;)if(ZI<YI){var Pia=XI.X(null,ZI);$CLJS.yE(Pia,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ti,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null)],null)],null)],null)]));ZI+=1}else{var $I=$CLJS.y(WI);if($I){var aJ=$I;if($CLJS.vd(aJ)){var bJ=$CLJS.ic(aJ),Qia=$CLJS.jc(aJ),
Ria=bJ,Sia=$CLJS.D(bJ);WI=Qia;XI=Ria;YI=Sia}else{var Tia=$CLJS.z(aJ);$CLJS.yE(Tia,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wq,new $CLJS.h(null,1,[$CLJS.ti,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aE],null)],null)],null)],null)]));WI=$CLJS.B(aJ);XI=null;YI=0}ZI=0}else break}
for(var cJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Jq,$CLJS.Lq,$CLJS.Fq,$CLJS.Hq],null)),dJ=null,eJ=0,fJ=0;;)if(fJ<eJ){var Uia=dJ.X(null,fJ);$CLJS.uE.l(LI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Uia,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null)]));fJ+=1}else{var gJ=$CLJS.y(cJ);if(gJ){var hJ=gJ;if($CLJS.vd(hJ)){var iJ=$CLJS.ic(hJ),Via=$CLJS.jc(hJ),Wia=iJ,Xia=$CLJS.D(iJ);cJ=Via;dJ=Wia;eJ=Xia}else{var Yia=
$CLJS.z(hJ);$CLJS.uE.l(LI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Yia,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null)]));cJ=$CLJS.B(hJ);dJ=null;eJ=0}fJ=0}else break}
$CLJS.uE.l(LI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),$CLJS.LE,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null)]));
$CLJS.uE.l(LI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[1,3],null),new $CLJS.P(null,2,5,$CLJS.Q,[1,5],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,4],null)])),$CLJS.RE,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.cE],null)]));
for(var jJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KE,$CLJS.WE],null)),kJ=null,lJ=0,mJ=0;;)if(mJ<lJ){var Zia=kJ.X(null,mJ);$CLJS.wE(Zia,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)]));mJ+=1}else{var nJ=$CLJS.y(jJ);if(nJ){var oJ=nJ;if($CLJS.vd(oJ)){var pJ=$CLJS.ic(oJ),$ia=$CLJS.jc(oJ),aja=pJ,bja=$CLJS.D(pJ);jJ=$ia;kJ=aja;lJ=bja}else{var cja=$CLJS.z(oJ);$CLJS.wE(cja,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)]));jJ=$CLJS.B(oJ);
kJ=null;lJ=0}mJ=0}else break}
for(var qJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ME,$CLJS.TE],null)),rJ=null,sJ=0,tJ=0;;)if(tJ<sJ){var dja=rJ.X(null,tJ);$CLJS.wE(dja,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)]));tJ+=1}else{var uJ=$CLJS.y(qJ);if(uJ){var vJ=uJ;if($CLJS.vd(vJ)){var wJ=$CLJS.ic(vJ),eja=$CLJS.jc(vJ),fja=wJ,gja=$CLJS.D(wJ);qJ=eja;rJ=fja;sJ=gja}else{var hja=$CLJS.z(vJ);$CLJS.wE(hja,$CLJS.H([$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)]));qJ=$CLJS.B(vJ);
rJ=null;sJ=0}tJ=0}else break}
for(var xJ=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null)],null),yJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.aF,$CLJS.uF,$CLJS.iF,$CLJS.bF],null)),zJ=null,AJ=0,BJ=0;;)if(BJ<AJ){var CJ=zJ.X(null,BJ);$CLJS.IF.v(CJ,$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ji,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,CJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.vD,xJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null));BJ+=1}else{var DJ=$CLJS.y(yJ);if(DJ){var EJ=DJ;if($CLJS.vd(EJ)){var FJ=$CLJS.ic(EJ),ija=$CLJS.jc(EJ),jja=FJ,kja=$CLJS.D(FJ);yJ=ija;zJ=jja;AJ=kja}else{var GJ=$CLJS.z(EJ);$CLJS.IF.v(GJ,$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ji,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,GJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.vD,xJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.fE],null)],null));yJ=$CLJS.B(EJ);zJ=null;AJ=0}BJ=0}else break}
$CLJS.IF.v($CLJS.cB,$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ji,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,$CLJS.cB],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.vD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Lk,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.er,$CLJS.Qu,$CLJS.Vw,$CLJS.QE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.iE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cD],null)],null));$CLJS.IF.v($CLJS.HE,$CLJS.Zr,$CLJS.mj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ji,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,$CLJS.HE],null),$CLJS.vD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,$CLJS.iD,$CLJS.qD],null)],null));
$CLJS.Y($CLJS.MI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,$CLJS.NI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.er,$CLJS.Mq,$CLJS.zF,$CLJS.RE,$CLJS.LE,$CLJS.Jq,$CLJS.Fq,$CLJS.Lq,$CLJS.Hq,$CLJS.KE,$CLJS.WE,$CLJS.ME,$CLJS.TE,$CLJS.iF,$CLJS.bF,$CLJS.aF,$CLJS.uF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OI,$CLJS.Gi],null)],null));