var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var l7,m7,n7,nta,ota,pta,q7,r7,t7,u7,qta,rta,sta,w7,x7,y7,tta,uta;l7=function(a){var b=new $CLJS.h(null,3,[$CLJS.xD,$CLJS.p.h($CLJS.HD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.pi,$CLJS.zz($CLJS.pi,$CLJS.tA)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,b,$CLJS.zz($CLJS.pD,$CLJS.U)(a)],null)};m7=function(a){return $CLJS.R.j(a,$CLJS.Vs,$CLJS.E4)};n7=function(a,b){return $CLJS.E.g($CLJS.kD.h(a),$CLJS.Hl(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BD,$CLJS.jD,$CLJS.AD],null)))};
nta=function(a){return $CLJS.Q0(a,new $CLJS.h(null,1,[$CLJS.BD,$CLJS.mh],null))};
ota=function(a,b){var c=$CLJS.Me(function(e){return $CLJS.pG(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KY,$CLJS.U],null));if($CLJS.n(c))return c;c=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",c))){var d=$CLJS.gD("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([a])),$CLJS.xh.l($CLJS.H([$CLJS.fk.g($CLJS.KY,b)]))]));d instanceof Error?$CLJS.Kz("metabase.lib.field",c,$CLJS.xv(),d):$CLJS.Kz("metabase.lib.field",
c,$CLJS.xv.l($CLJS.H([d])),null)}return null};
pta=function(a,b,c){if($CLJS.n(o7))return null;var d=o7;o7=!0;try{var e=$CLJS.J1(a,b),f=$CLJS.n(e)?$CLJS.EV(a,e):$CLJS.EV(a,b),k=function(){var m=$CLJS.p7.h(f);if($CLJS.n(m))return m;m=$CLJS.Bz(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nU,$CLJS.BV],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.NU.h(f);if($CLJS.n(u))return u;u=$CLJS.eO.h(f);if($CLJS.n(u))return u;u=$CLJS.ZO.h(f);return $CLJS.n(u)?u:$CLJS.RD.h(f)}())?$CLJS.Y_.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",
m))){var t=$CLJS.gD("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.Kz("metabase.lib.field",m,$CLJS.xv(),t):$CLJS.Kz("metabase.lib.field",m,$CLJS.xv.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?ota(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.k3($CLJS.Wj.l(l,$CLJS.Ii,$CLJS.H([$CLJS.XJ,$CLJS.NV,$CLJS.IV])),null),$CLJS.U,function(){var m=$CLJS.KY.h(l);return $CLJS.n(m)?m:$CLJS.U.h(l)}()),
$CLJS.KJ,$CLJS.vY):l:null}finally{o7=d}};
q7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.tO);var f=$CLJS.I(c,2,null);c=$CLJS.vk.l($CLJS.H([function(){var k=$CLJS.tA.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.tA,k],null):null}(),function(){var k=$CLJS.zz($CLJS.pi,$CLJS.tA)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.pi,k],null):null}(),function(){var k=$CLJS.tQ.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.NV,k],null):null}(),function(){var k=$CLJS.pF.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.IV,k],null):null}(),$CLJS.Ad(f)?function(){var k=$CLJS.P1(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.IJ,$CLJS.U,$CLJS.p.h(f)],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.IJ,$CLJS.U,$CLJS.p.h(f)],null):function(){var k=pta(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.IJ,$CLJS.U,$CLJS.p.h(f)],null)}()]));return $CLJS.n(d)?$CLJS.k3(c,d):c};
r7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.IV);return $CLJS.n($CLJS.n(b)?$CLJS.Cd($CLJS.mG,b):b)?$CLJS.Zi:$CLJS.zz($CLJS.pi,$CLJS.tA)(a)};
$CLJS.s7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.xD),l=$CLJS.J.g(f,$CLJS.tA),m=$CLJS.J.g(f,$CLJS.tQ),t=$CLJS.J.g(f,$CLJS.pi);e=$CLJS.J.g(f,$CLJS.tO);var u=$CLJS.J.g(f,$CLJS.EM),v=$CLJS.J.g(f,$CLJS.pF),x=$CLJS.vk.l;k=new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.IJ,$CLJS.D0,k],null);f=$CLJS.lD.h(f);a=$CLJS.n(f)?f:$CLJS.l_.j(a,b,d);c=x.call($CLJS.vk,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.lD,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.pi,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.tA,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.IV,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.NV,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.WJ,u):m;return $CLJS.n(e)?$CLJS.k3(u,e):u};t7=function(a,b,c){return $CLJS.n($CLJS.Me(function(d){return $CLJS.E.g($CLJS.ei.h(d),c)},a))?$CLJS.fk.g(function(d){var e=$CLJS.Cd(d,b)?$CLJS.Wj.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.ei.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
u7=function(a){var b=$CLJS.n($CLJS.q1.h(a))?null:function(){var e=$CLJS.KJ.h(a),f=new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.OY,null,$CLJS.VY,null,$CLJS.vY,null],null),null);return f.h?f.h(e):f.call(null,e)}(),c=$CLJS.vk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.xD,$CLJS.p.h($CLJS.HD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.pi,r7(a)],null),function(){var e=function(){var f=$CLJS.Ra(b);return f?(f=$CLJS.Ra($CLJS.WJ.h(a)))?(f=$CLJS.ck.g($CLJS.GY,$CLJS.KJ.h(a)))?$CLJS.ZY.h(a):f:f:f}();return $CLJS.n(e)?new $CLJS.h(null,
1,[$CLJS.tO,e],null):null}(),function(){var e=$CLJS.j1(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.tO,e],null):null}(),function(){var e=$CLJS.IV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.pF,e],null):null}(),function(){var e=$CLJS.MV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.MV,e],null):null}(),function(){var e=$CLJS.NV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.tQ,e],null):null}(),function(){var e=$CLJS.WJ.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.EM,e],null):null}()])),
d=($CLJS.n(b)?$CLJS.zz($CLJS.KY,$CLJS.U):$CLJS.zz($CLJS.Ii,$CLJS.U))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,c,d],null)};qta=function(a,b){return $CLJS.ff(function(c){return $CLJS.E.g($CLJS.KJ.h(c),$CLJS.EY)},$CLJS.Y_.v(a,b,$CLJS.EV(a,b),new $CLJS.h(null,3,[$CLJS.F_,!1,$CLJS.L_,!0,$CLJS.B_,!1],null)))};rta=new $CLJS.M("lib","simple-name","lib/simple-name",-734346338);sta=new $CLJS.M("metabase.lib.field","field-values-search-info","metabase.lib.field/field-values-search-info",-468454179);
$CLJS.v7=new $CLJS.M(null,"search-field-id","search-field-id",-699326724);w7=new $CLJS.M("metabase.lib.field","field-values-search-info.has-field-values","metabase.lib.field/field-values-search-info.has-field-values",1753198226);x7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.p7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);y7=new $CLJS.M("metabase.lib.field","simple-display-name","metabase.lib.field/simple-display-name",494797345);
tta=new $CLJS.M(null,"earliest","earliest",-1928154382);uta=new $CLJS.M(null,"latest","latest",24323665);$CLJS.R0.m(null,$CLJS.jF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mh.h(b),$CLJS.Q0(c,new $CLJS.h(null,2,[$CLJS.pF,$CLJS.mh,$CLJS.tQ,nta],null)),a],null)});
var o7=!1,z7=function z7(a,b){var d=$CLJS.$0(a,x7.h(b));a=$CLJS.n(x7.h(d))?z7.g?z7.g(a,d):z7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.U),f=$CLJS.J.g(a,$CLJS.lD);return $CLJS.gk.j($CLJS.R.j($CLJS.gk.j($CLJS.R.j(b,rta,$CLJS.U.h(b)),$CLJS.U,function(k){return[$CLJS.p.h(e),".",$CLJS.p.h(k)].join("")}),y7,$CLJS.lD.h(b)),$CLJS.lD,function(k){return[$CLJS.p.h(f),": ",$CLJS.p.h(k)].join("")})};$CLJS.Q_.m(null,$CLJS.IJ,function(a,b,c){return r7(c)});
$CLJS.Q_.m(null,$CLJS.jF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.pF);$CLJS.I(c,2,null);c=q7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.IV,d):c;return $CLJS.S_.j(a,b,c)});$CLJS.T_.m(null,$CLJS.IJ,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.U);return $CLJS.R.j(a,$CLJS.U,b)});$CLJS.T_.m(null,$CLJS.jF,function(a,b,c){var d=q7(a,b,c);b=$CLJS.s7(a,b,d,c);return $CLJS.n(x7.h(b))?z7(a,b):b});
$CLJS.M_.m(null,$CLJS.IJ,function(a,b,c,d){function e(T){var da=$CLJS.k5(l,k);return $CLJS.ha.j?$CLJS.ha.j("%s: %s",T,da):$CLJS.ha.call(null,"%s: %s",T,da)}function f(T){var da=$CLJS.cA($CLJS.Gz($CLJS.Xg(u),"-"," "));return $CLJS.ha.j?$CLJS.ha.j("%s: %s",T,da):$CLJS.ha.call(null,"%s: %s",T,da)}var k=$CLJS.O(c),l=$CLJS.J.g(k,$CLJS.NV),m=$CLJS.J.g(k,$CLJS.WJ),t=$CLJS.J.g(k,$CLJS.XJ),u=$CLJS.J.g(k,$CLJS.ei),v=$CLJS.J.g(k,$CLJS.ZY),x=$CLJS.J.g(k,y7),A=$CLJS.J.g(k,$CLJS.U),C=$CLJS.J.g(k,$CLJS.K5),G=$CLJS.J.g(k,
$CLJS.lD),K=$CLJS.J.g(k,x7),S=$CLJS.h1.g($CLJS.g1,A);c=function(){if($CLJS.n(x))return x;if($CLJS.n($CLJS.n(K)?null==G||$CLJS.E.g(G,S):K)){a:{var T=$CLJS.O(k);var da=$CLJS.J.g(T,$CLJS.lD);T=$CLJS.J.g(T,x7);for(da=new $CLJS.Vd(null,da,null,1,null);;)if($CLJS.n(T)){T=$CLJS.$0(a,T);var oa=$CLJS.O(T);T=$CLJS.J.g(oa,$CLJS.lD);oa=$CLJS.J.g(oa,x7);da=$CLJS.Yd.g(da,T);T=oa}else break a}da=$CLJS.Le($CLJS.Sa,da)?$CLJS.hs(": ",da):null}else da=null;return $CLJS.n(da)?da:$CLJS.n(G)?G:"string"===typeof A?S:$CLJS.p.h(A)}();
var X=$CLJS.E.g(d,$CLJS.m_)&&-1==c.indexOf(" → ")?function(){if($CLJS.n(m)){var T=$CLJS.$0(a,m);$CLJS.n(T)?T=$CLJS.g3($CLJS.lD.h($CLJS.o_.j(a,b,T))):(T=$CLJS.i1(a,t),T=$CLJS.l_.v(a,b,T,d))}else T=null;return $CLJS.n(T)?T:$CLJS.n(v)?v:$CLJS.j1(k)}():null;c=$CLJS.n(X)?[$CLJS.p.h(X)," → ",$CLJS.p.h(c)].join(""):c;return $CLJS.n(function(){var T=$CLJS.ck.g(d,$CLJS.m_);return T?C:T}())?c:$CLJS.n($CLJS.n(u)?$CLJS.ck.g(c,f(S)):u)?f(c):$CLJS.n($CLJS.n(l)?$CLJS.ck.g(c,e(S)):l)?e(c):c});
$CLJS.M_.m(null,$CLJS.jF,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.tQ);var k=$CLJS.J.g(f,$CLJS.tO),l=$CLJS.J.g(f,$CLJS.pF);f=$CLJS.J.g(f,$CLJS.EM);$CLJS.I(c,2,null);c=q7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.ZY,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.ei,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.NV,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.WJ,f):e;return $CLJS.n(e)?$CLJS.l_.v(a,b,e,d):$CLJS.ID("[Unknown Field]")});
$CLJS.N_.m(null,$CLJS.IJ,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.U)});$CLJS.N_.m(null,$CLJS.jF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=q7(a,b,c);return $CLJS.n(c)?$CLJS.O_.j(a,b,c):"unknown_field"});
$CLJS.U_.m(null,$CLJS.IJ,function(a,b,c){return $CLJS.vk.l($CLJS.H([function(){var d=$CLJS.iH($CLJS.U_,$CLJS.Oh);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.l_.j(a,b,c),$CLJS.n_,$CLJS.l_.v(a,b,c,$CLJS.m_)],null),$CLJS.E.g($CLJS.KJ.h(c),$CLJS.OY)?function(){var d=$CLJS.YY.h(c);return $CLJS.n(d)?(d=$CLJS.f_(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.Ij,new $CLJS.h(null,2,[$CLJS.U,$CLJS.U.h(d),$CLJS.lD,$CLJS.U.h(d)],null)],null):null):null}():null]))});
$CLJS.u0.m(null,$CLJS.jF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.pF.h(b)});$CLJS.u0.m(null,$CLJS.IJ,function(a){return $CLJS.IV.h(a)});
$CLJS.t0.m(null,$CLJS.jF,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Cd($CLJS.mG,b),e=$CLJS.pB($CLJS.MV,$CLJS.pi,$CLJS.tA)(c);c=$CLJS.R.l(c,$CLJS.pF,b,$CLJS.H([$CLJS.pi,d?$CLJS.Zi:e,$CLJS.MV,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,c,a],null)}b=$CLJS.MV.h(c);c=$CLJS.n(b)?$CLJS.Wj.g($CLJS.R.j(c,$CLJS.pi,b),$CLJS.MV):c;c=$CLJS.Wj.g(c,$CLJS.pF);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,c,a],null)});
$CLJS.t0.m(null,$CLJS.IJ,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.IV,b,$CLJS.H([$CLJS.MV,$CLJS.pB($CLJS.MV,$CLJS.pi,$CLJS.tA)(a)])):$CLJS.Wj.l(a,$CLJS.IV,$CLJS.H([$CLJS.MV]))});$CLJS.w0.m(null,$CLJS.jF,function(a,b,c){return $CLJS.x0.j(a,b,q7(a,b,c))});
$CLJS.w0.m(null,$CLJS.IJ,function(a,b,c){if($CLJS.ck.g($CLJS.KJ.h(c),$CLJS.EY)){a=$CLJS.zz($CLJS.pi,$CLJS.tA)(c);b=null==c?null:$CLJS.SM.h(c);if(null==b)var d=null;else try{var e=$CLJS.Ik.h($CLJS.Si.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,tta),l=$CLJS.J.g(f,uta),m=$CLJS.kpa.l($CLJS.H([$CLJS.r0.h(k),$CLJS.r0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Gq.g?$CLJS.Gq.g(1,m):$CLJS.Gq.call(null,1,m))?$CLJS.Gk:$CLJS.n($CLJS.Gq.g?$CLJS.Gq.g(31,m):$CLJS.Gq.call(null,31,m))?$CLJS.Pk:$CLJS.n($CLJS.Gq.g?
$CLJS.Gq.g(365,m):$CLJS.Gq.call(null,365,m))?$CLJS.Ui:$CLJS.$i}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.Dz(a,$CLJS.Ik)?$CLJS.vpa:$CLJS.Dz(a,$CLJS.Bk)?$CLJS.upa:$CLJS.Dz(a,$CLJS.Qk)?$CLJS.tpa:$CLJS.xf;d=$CLJS.n(d)?t7(e,$CLJS.Oh,d):e;return $CLJS.n($CLJS.IV.h(c))?t7(d,$CLJS.t_,$CLJS.IV.h(c)):d}return $CLJS.xf});
$CLJS.h5.m(null,$CLJS.jF,function(a){var b=null==a?null:$CLJS.ED(a);b=null==b?null:$CLJS.tQ.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Vs,$CLJS.m5,$CLJS.H([$CLJS.l5,function(c,d){return q7(c,d,a)}]))});$CLJS.h5.m(null,$CLJS.IJ,function(a){var b=null==a?null:$CLJS.NV.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Vs,$CLJS.m5,$CLJS.H([$CLJS.l5,$CLJS.Pe(a)]))});$CLJS.n5.m(null,$CLJS.jF,function(a,b){return $CLJS.FD(a,$CLJS.DD,$CLJS.H([$CLJS.tQ,b]))});
$CLJS.n5.m(null,$CLJS.IJ,function(a,b){return $CLJS.DD(a,$CLJS.NV,b)});$CLJS.o5.m(null,$CLJS.jF,function(a,b,c){return $CLJS.p5.j(a,b,q7(a,b,c))});
$CLJS.o5.m(null,$CLJS.IJ,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.pi);var d=$CLJS.J.g(b,$CLJS.SM),e=$CLJS.J.g(b,$CLJS.sj);if($CLJS.ck.g($CLJS.KJ.h(b),$CLJS.EY)){var f=function(){var m=null==a?null:$CLJS.Z0(a);m=null==m?null:$CLJS.dz.h(m);return null==m?null:$CLJS.Cd(m,$CLJS.tQ)}(),k=$CLJS.Bz(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,$CLJS.oj],null)),l=$CLJS.i5(b);return function u(t){return new $CLJS.ie(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.vd(v)){var x=$CLJS.ic(v),A=$CLJS.D(x),
C=$CLJS.le(A);return function(){for(var K=0;;)if(K<A){var S=$CLJS.hd(x,K),X=C,T=S;S=n7(S,l)?$CLJS.R.j(T,$CLJS.t_,!0):T;X.add(S);K+=1}else return!0}()?$CLJS.oe($CLJS.qe(C),u($CLJS.jc(v))):$CLJS.oe($CLJS.qe(C),null)}var G=$CLJS.z(v);return $CLJS.ae(function(){var K=G;return n7(G,l)?$CLJS.R.j(K,$CLJS.t_,!0):K}(),u($CLJS.Hc(v)))}return null}},null,null)}($CLJS.Ra(function(){if($CLJS.n(f)){var t=$CLJS.ti.h(k);return $CLJS.n(t)?$CLJS.Cj.h(k):t}return f}())?null:$CLJS.Dz(e,$CLJS.aj)?$CLJS.fk.g(m7,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.j5(),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.ID("Bin every 0.1 degrees"),$CLJS.kD,new $CLJS.h(null,2,[$CLJS.BD,$CLJS.AD,$CLJS.AD,.1],null)],null),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.ID("Bin every 1 degree"),$CLJS.kD,new $CLJS.h(null,2,[$CLJS.BD,$CLJS.AD,$CLJS.AD,1],null)],null),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.ID("Bin every 10 degrees"),$CLJS.kD,new $CLJS.h(null,2,[$CLJS.BD,$CLJS.AD,$CLJS.AD,10],null)],null),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.ID("Bin every 20 degrees"),$CLJS.kD,new $CLJS.h(null,
2,[$CLJS.BD,$CLJS.AD,$CLJS.AD,20],null)],null)],null)):$CLJS.Dz(c,$CLJS.oj)&&!$CLJS.Dz(e,$CLJS.ai)?$CLJS.fk.g(m7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.j5(),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.ID("10 bins"),$CLJS.kD,new $CLJS.h(null,2,[$CLJS.BD,$CLJS.jD,$CLJS.jD,10],null)],null),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.ID("50 bins"),$CLJS.kD,new $CLJS.h(null,2,[$CLJS.BD,$CLJS.jD,$CLJS.jD,50],null)],null),new $CLJS.h(null,2,[$CLJS.lD,$CLJS.ID("100 bins"),$CLJS.kD,new $CLJS.h(null,2,[$CLJS.BD,$CLJS.jD,$CLJS.jD,
100],null)],null)],null)):null)}return $CLJS.xf});$CLJS.nV.m(null,$CLJS.jF,function(a){return a});
$CLJS.nV.m(null,$CLJS.IJ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.KJ);switch(b instanceof $CLJS.M?b.S:null){case "source/aggregations":return b=new $CLJS.h(null,3,[$CLJS.xD,$CLJS.p.h($CLJS.HD()),$CLJS.pi,$CLJS.zz($CLJS.pi,$CLJS.tA)(a),$CLJS.yK,$CLJS.U.h(a)],null),a=$CLJS.D0.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZE,b,a],null);case "source/expressions":return l7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.pD.h(a))?l7(a):u7(a);default:return u7(a)}});
$CLJS.vta=function(){function a(e,f,k){k=$CLJS.Be($CLJS.fk.g($CLJS.pV,k));var l=qta(e,f),m=$CLJS.Wf.j($CLJS.Sg,$CLJS.zl(function(t){return $CLJS.V1.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.xf);m=$CLJS.ek.g(m,l);k=$CLJS.n(k)?$CLJS.Wf.j(k,$CLJS.cf.h($CLJS.pV),m):null;return $CLJS.PV.l(e,f,$CLJS.DD,$CLJS.H([$CLJS.RD,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.A7=function(){function a(d,e){return $CLJS.RD.h($CLJS.EV(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.wta=function(){function a(d,e){var f=$CLJS.Y_.v(d,e,$CLJS.EV(d,e),new $CLJS.h(null,3,[$CLJS.F_,!1,$CLJS.L_,!1,$CLJS.B_,!1],null)),k=$CLJS.A7.g(d,e);return $CLJS.od(k)?$CLJS.fk.g(function(l){return $CLJS.R.j(l,$CLJS.MY,!0)},f):$CLJS.i3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Y(w7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.er,$CLJS.dj,$CLJS.yY,$CLJS.cz],null));
$CLJS.Y(sta,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.v7,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,w7],null)],null)],null));